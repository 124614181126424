// Migrated
<template lang="pug">
.col-12.px-0.calendar-view-mode.mb-1.pb-3.mt-3.d-flex.justify-content-between.align-items-center.flex-column.flex-xl-row(ref="navBar")
  .my-2.calendar-view-mode-type
    FilterBarType(
      :airports="airports"
      @filter="$emit('filter')"
      @update="onUpdate"
    )

  .d-flex.justify-content-end.align-items-center.flex-column.flex-sm-row.my-2
    button.btn-link.p-2.ml-3.text-black.category-mode-toggle-btn(
      v-for="{ id, key } in options",
      :key="id"
      :class="{ 'text-blue font-weight-bold': currentId == id }",
      @click="$emit('click', id)"
    ) {{ $t(key) }}
</template>

<script>
export default defineNuxtComponent({
  props: {
    currentId: {
      type: String,
      default: ''
    },

    options: {
      type: Array,
      default: () => []
    },

    category: {
      type: Object,
      default: () => {}
    }
  },

  emits: ['update', 'filter', 'click'],

  computed: {
    airports () {
      return [
        {
          iata: 'all',
          name: this.$t('allAirports'),
        },
        // yes, it's misspelled.
        ...this.category?.availible_airports || []
      ]
    }
  },

  methods: {
    onUpdate (value) {
      this.$emit('update', value)
    },
  }
})
</script>

<style lang="scss" scoped>
/*! purgecss start ignore */
.calendar-view-mode {
  .calendar-view-mode-type {
    @media (max-width: $sm) {
      width: 100%;
    }
  }
  .category-mode-toggle-airport {
    border: 1px solid $light-gray;
  }
  button.category-mode-toggle-btn {
    letter-spacing: 0.34px;
    position: relative;

    &:focus, &:active {
      text-decoration: none;
    }
    @media (min-width: $sm) {
      & + button {
        &:before {
          content: "\a0";
          display: block;
          height: calc(100% - 1.25rem);
          top: .625rem;
          bottom: .625rem;
          width: 1px;
          position: absolute;
          left: -.5rem;
          background: gray;
        }
      }
    }
  }
}
/*! purgecss end ignore */
</style>
