export const AIRPORT_COOKIE_NAME = 'rbAirport'
export const TYPE_COOKIE_NAME = 'rbTypeSelection'

export const compileCategoryParams = (airport, selectedTripType, slug, triptypes = []) => {
  const params = {}

  if (triptypes) {
    if (Array.isArray(triptypes) && (triptypes || []).length > 0) {
      params.triptypes = triptypes.join(',')
    } else {
      params.triptypes = triptypes
    }
  }
  if (slug) {
    params.category_url = slug
  }
  if (selectedTripType === 'bus') {
    params.onlyflight = 0
    params.onlybus = 1
  } else if (selectedTripType === 'all') {
    params.onlyflight = 0
    params.onlybus = 0
  } else if (airport) {
    params.onlyflight = 1
    params.onlybus = 0
    params.airport = airport
  }

  return params
}
